@charset "utf-8";

@import '../../_inc/variables.scss';
@import '../../_inc/mixins.scss';

.global-nav {
  &.-pc {
    .list-global__nav {
      > li:nth-child(3) {
        > .list-gn__child {
          display: table;
          .child-col:nth-child(7) {
            > a {
              color: #043a77;
            }
          }
        }
        > a {
          background: #FFF;
          color: #043a77;
          text-decoration: none;
        }
      }
    }
  }
}

.hero-img {
  background-image: url(../../../support/img/page-title.jpg?v=d0305);
}

.item-filter {
  font-weight: bold;
  @include mq(sp) {
    padding: 0.5rem 0;
    border: 1px solid #ccc;
    border-top: none;
  }
  @include mq() {
    padding-right: 1em;
  }
}

.list-pages {
  > .cat-all {
    a {
      color: #043a77;
      &:hover,
      &.current {
        background: #043a77;
        border-color: #043a77;
        color: #fff;
      }
    }
  }
  > .cat-01 {
    a {
      color: #997e04;
      &:hover,
      &.current {
        background: #997e04;
        border-color: #997e04;
        color: #fff;
      }
    }
  }
  > .cat-02 {
    a {
      color: #5da61d;
      &:hover,
      &.current {
        background: #5da61d;
        border-color: #5da61d;
        color: #fff;
      }
    }
  }
  > .cat-03 {
    a {
      color: #654bd6;
      &:hover,
      &.current {
        background: #654bd6;
        border-color: #654bd6;
        color: #fff;
      }
    }
  }
  > .cat-04 {
    a {
      color: #d85b6a;
      &:hover,
      &.current {
        background: #d85b6a;
        border-color: #d85b6a;
        color: #fff;
      }
    }
  }
}

.select-year {
  padding: 5px 60px;
  background: url(../../common/img/icon-form_select_w.png) no-repeat 96% center #043a77;
  background-size: 12px auto;
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  @include mq() {
    padding: 8px 80px;
  }
  > option {
    background-color: #fff;
    color: $main_text;
  }
}

.calendar-column {
  &.-loading {
    padding: 5vw 0;
    width: 100%;
    background: url(../../img/calendar/ajax-loader.gif) no-repeat center;
  }
}

.list-month {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  @include mq() {
    display: table;
  }
  > li {
    @include mq(sp) {
      float: left;
      margin-bottom: 3px;
      width: 16.6%;
    }
    @include mq() {
      display: table-cell;
    }
    > a {
      display: block;
      margin: auto;
      padding: 10px 0;
      width: 98%;
      background: #d5dff1;
      border-radius: 5px;
      color: #043a77;
      font-weight: bold;
      line-height: 1;
      text-align: center;
      text-decoration: none;
      @include mq(sp) {
        width: 95%;
        @include fontCalc(14);
      }
      @include mq() {
        width: 98%;
        border-radius: 10px 10px 0 0;
      }
      &:hover,
      &.current {
        background: #043a77;
        color: #fff;
      }
    }
  }
}
